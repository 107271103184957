@import "../utils/variables";

// /*** Themes ***/
@import "../themes/TARGETconnect/theme";
@import "../themes/TARGETconnect/custom"; 
@import "../themes/TARGETconnect/fonts";

/*** Mixins ***/
@import "../utils/mixins";

/*** Vendors ***/
@import "../utils/vendors";

// /*** Base ***/
@import "../utils/base";

// /*** Components ***/
@import "../utils/components";

/*** Custom Layout ***/
@import "../themes/TARGETconnect/layout/custom";
@import "../themes/TARGETconnect/layout/job-posting";  
@import "../themes/TARGETconnect/layout/unauthorised";
@import "../themes/TARGETconnect/layout/swipecard";
@import "../themes/TARGETconnect/layout/awards"; 

/*** Migration : Sherlock ****/
@import "../themes/default/layout/migration/sherlock/migration";

/*** Migration - Foundation : Romeo ****/
@import "../themes/default/layout/migration/romeo";

// /*** Layout ***/
@import "../utils/layout";
