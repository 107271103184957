////////////////////////////////////////////////////
//// TARGETCONNECT student/employer theme!
////////////////////////////////////////////////////

// Romeo :: colours and banner/logo variables for the student/employer interface
$romeo-primary:                 #4E21FF;
$romeo-secondary:               #334095;
$romeo-tertiary:                #2EBCB4;
$romeo-quaternary:              #F65264;
$primary:                       $romeo-primary;
$secondary:                     $romeo-secondary;
// navigation
$romeo-nav-items-hover:         $romeo-secondary;
// tables
$romeo-table-header-bg-color:   $romeo-secondary;
// banner
$romeo-main-banner-brand-padding: 2rem 1rem;  // controlling the size of the logo
$romeo-main-banner-brand-bg:    #fff;

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary":          $romeo-primary,
    "secondary":        $romeo-secondary,
    "tertiary":         $romeo-tertiary,
    "quaternary":       $romeo-quaternary,
    "success":          $success,
    "success-muted":    $success-muted,
    "info":             $info,
    "info-muted":       $info-muted,
    "warning":          $warning,
    "warning-muted":    $warning-muted,
    "danger":           $danger,
    "danger-muted":     $danger-muted,
    "light":            $light,
    "dark":             $dark,
    "default":          $default,
    "inferno-primary":        $inferno-primary,
    "inferno-secondary":      $inferno-secondary,
    "inferno-primary-hover":  $inferno-primary-hover,
    "inferno-secondary-hover":$inferno-secondary-hover,
  ),
  $theme-colors
);
// Link colour for unauth pages
$link-color:            $romeo-primary;
$link-hover-color:      $romeo-secondary;