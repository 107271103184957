
.side-section {
    h2 {
        color: $romeo-primary !important; 
    }
}

.version {
    padding-left: 1rem;
}

.widget-block,
.widget-simple,
.widget-simple-plain,
.widget-simple-stat {  
    border-radius: 0.6rem !important;
    background-color: #FFF;
    -webkit-border-radius: 0.6rem;
    -moz-border-radius: 0.6rem; 
}

.widget-simple {
    -moz-box-shadow: 3px 4px 47px -9px rgba(0,0,0,0.35);
    box-shadow: 3px 4px 47px -9px rgba(0,0,0,0.35);
}

.widget-links-list li .widget-link-details .widget-link-details-description {
    color: #333 !important;
}

.widget-simple-plain i {
    border-radius: 0.3rem;
}

.widget-block h2,
.widget-simple-plain h3 {
    color: $romeo-secondary;
}

.widget-simple-plain {
    .fa-graduation-cap {
        color: #fff;
    }
}

.widget-link-details {
    font-weight: 500;
    .widget-link-details-description {
        font-weight: normal;
    }
}

@media (min-width: 992px) {

    .widget-simple {
        margin-bottom: 60px !important;
    }
    
}

//appointemnt types fix

._queue-definition {
    >div.row {
        margin-right: 0;
    }

    .fa.fa-times {
        left: 11px;
    }
}

#dynamic-advanced-search-container {
    border: none;

    a:hover {
        color: $primary-text-color;
        text-decoration: none;
    }

    #dynamic-advanced-search-heading {
        background-color: $romeo-primary;
        border-color: $romeo-primary;
    
    }
}

.btn-tab {
    border-radius: 0.3rem 0.3rem 0 0 !important; 
}

.submenu {
	.navbar {
        border-top: 3px solid white;
    }
}

.submenu .navbar .navbar-nav li a:hover, 
.submenu .navbar .navbar-nav li a:focus {
    background-color: #038be4; 
}

// AB TC-16849 helper class not working on mobile
// Mobile - Organisation registration - Opportunity management page doesn't fit the screen


.employer-registration-flow.advertise-redirect {
    .unauthroised-body {
        .content-block {
            .row-eq-height.row-eq-height-center {
                display: block;
                padding: 0 2rem;

                @media (min-width: 992px) {
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                }

                .widget-simple-action-center {
                    margin: 0 auto 1rem auto;

                    @media (min-width: 992px) {
                        margin: inherit;
                    }

                    h2 {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}